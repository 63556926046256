import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Form, notification, Typography } from 'antd'
import { FileDoneOutlined, DeleteOutlined  } from '@ant-design/icons'
import AppPrimaryButton from '../../../../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import './addFileTab.less'
import { useUploadFileMutation } from '../../../../../../../../store/api/uploads.api'
import { useCreateDocumentMutation } from '../../../../../../../../store/api/document.api'
import { useAppSelector } from '../../../../../../../../hooks/appHook'
import { useLocation, useNavigate } from 'react-router-dom'

type AddFileTabProps = {
  setKnowledgeBasePage: (key: string) => void
}

const AddFileTab = ({ setKnowledgeBasePage }: AddFileTabProps) => {

  const [filesData, setFilesData] = useState<File[]>([])
  const maxSize = 20 * 1024 * 1024 // 20 MB
  const navigate = useNavigate()
  const location = useLocation()
  /** Storage */
  const {
    currentStorageId,
  } = useAppSelector((state) => state.storage)

  /* api */
  const [uploadFile, uploadFileMutation] = useUploadFileMutation()
  const [createDocument, createDocumentMutation] = useCreateDocumentMutation()
  const isLoading = createDocumentMutation.status === 'pending' || uploadFileMutation.status === 'pending'

  /* handlers */
  const onDropAccepted = (files: File[]) => {
    setFilesData([files[0]])
  }

  const onDropRejected = (fileRejections: any) => {
    fileRejections.forEach(({ file, errors }: any) => {
      errors.forEach((err: any) => {
        if (err.code === 'file-too-large') {
          notification.error({ message: `Error: File ${file.name} is too large. Max size is 20 MB.` })
        } else if (err.code === 'file-invalid-type') {
          notification.error({
            message: `Error: File ${file.name} has an invalid format.`,
          })
        }
      })
    })
  }

  const onSubmit = async () => {
    const formData = new FormData()
    formData.append('file', filesData[0])

    const searchParams = new URLSearchParams(location.search)

    try {
      const result = await uploadFile({ body: formData }).unwrap()
      if (result?.s3Uri) {
        const createdDocument = await createDocument({
          storageId: currentStorageId, file: {
            s3Uri: result.s3Uri, publicUrl: result.url,
          },
        }).unwrap()
        if (createdDocument.id) {
          notification.success({ message: `Successfully uploaded.` })
          setKnowledgeBasePage('4')
          searchParams.set('documentId', createdDocument.id)
          navigate(`${location.pathname}?${searchParams.toString()}`)
        }
      }

    } catch (e: any) {
      console.log(e)
      notification.error({ message: `Something error happened, please try again.` })
    }
  }

  //* dropzone */
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/markdown': ['.md'],
      'text/plain': ['.txt'],
    },
    maxSize,
    onDropAccepted,
    onDropRejected,
    maxFiles: 1,
  })

  const onDeleteFileFromUploads=(fileName: string)=>{
    const filteredFiles = filesData.filter(file => file.name !== fileName)
    setFilesData(filteredFiles)
  }

  return (
    <Form className="form-ai-helper-col-wrapper form-ai-helper-col-wrapper-align-left" onFinish={onSubmit}>
      <h3>Upload PDF, Doc, Docx, MD or txt files and we will automatically create vectors</h3>

      <div
        {...getRootProps({
          className: 'drag-and-drop',
        })}
      >
        <input {...getInputProps()} />
        {filesData?.length ? (filesData.map(file => {
          return (<div key={file.name} >
            <FileDoneOutlined className="file-icon" />
            <Typography className="file-text">{file.name}</Typography>
            <DeleteOutlined className='delete-file-icon'  onClick={(e)=>{
              e.stopPropagation()
              onDeleteFileFromUploads(file.name)
            }}/>
          </div>)
        })) : (
          <p>Drag & drop file here, or click to select file (Max size: 20 MB)</p>)}
      </div>
      <AppPrimaryButton
        type="primary"
        size="middle"
        htmlType="submit"
        className="submit-button"
        disabled={!filesData?.length}
        loading={isLoading}
      >
        Upload file
      </AppPrimaryButton>
    </Form>
  )
}
export default AddFileTab
